import React from 'react';
import "./Texts.scss"

const Texts = () => {
    return (
        <div className="container mx-auto">
            <div className="policy-texts-over flex justify-between">
                <div></div>
                <div className="policy-texts">
                    <h2>Политика в отношении обработки персональных данных</h2>
                    <div className="policy-texts-wrapper">
                        <div className="policy-texts-chapter chapter-one">
                            <h4>1. Общие положения</h4>
                            <p>
                                Настоящая политика обработки персональных данных составлена в соответствии с
                                требованиями
                                Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок
                                обработки персональных данных и меры по обеспечению безопасности персональных данных
                                Станкевича Сергея Александровича (далее – Оператор).
                            </p>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>1.</p>
                                <p>
                                    Оператор ставит своей важнейшей целью и условием осуществления своей деятельности
                                    соблюдение прав и свобод человека и гражданина при обработке его персональных
                                    данных, в том числе защиты прав на неприкосновенность частной жизни, личную и
                                    семейную тайну.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>2.</p>
                                <p>
                                    Настоящая политика Оператора в отношении обработки персональных данных (далее –
                                    Политика) применяется ко всей информации, которую Оператор может получить о
                                    посетителях веб-сайта <a href="https://stoodio.vercel.app">stoodio.uz</a>.
                                </p>
                            </div>
                        </div>
                        <div className="policy-texts-chapter chapter-two mt-12">
                            <h4>2. Основные понятия, используемые в Политике</h4>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>1.</p>
                                <p>
                                    Автоматизированная обработка персональных данных – обработка персональных данных с
                                    помощью средств вычислительной техники;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>2.</p>
                                <p>
                                    Блокирование персональных данных – временное прекращение обработки персональных
                                    данных (за исключением случаев, если обработка необходима для уточнения персональных
                                    данных);
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>3.</p>
                                <p>
                                    Веб-сайт – совокупность графических и информационных материалов, а также программ
                                    для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому
                                    адресу <a href="https://stoodio.vercel.app">stoodio.uz</a>.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>4.</p>
                                <p>
                                    Информационная система персональных данных — совокупность содержащихся в базах
                                    данных персональных данных, и обеспечивающих их обработку информационных технологий
                                    и технических средств;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>5.</p>
                                <p>
                                    Обезличивание персональных данных — действия, в результате которых невозможно
                                    определить без использования дополнительной информации принадлежность персональных
                                    данных конкретному Пользователю или иному субъекту персональных данных;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>6.</p>
                                <p>
                                    Обработка персональных данных – любое действие (операция) или совокупность действий
                                    (операций), совершаемых с использованием средств автоматизации или без использования
                                    таких средств с персональными данными, включая сбор, запись, систематизацию,
                                    накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
                                    передачу (распространение, предоставление, доступ), обезличивание, блокирование,
                                    удаление, уничтожение персональных данных;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>7.</p>
                                <p>
                                    Оператор – государственный орган, муниципальный орган, юридическое или физическое
                                    лицо, самостоятельно или совместно с другими лицами организующие и (или)
                                    осуществляющие обработку персональных данных, а также определяющие цели обработки
                                    персональных данных, состав персональных данных, подлежащих обработке, действия
                                    (операции), совершаемые с персональными данными;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>8.</p>
                                <p>
                                    Персональные данные – любая информация, относящаяся прямо или косвенно к
                                    определенному или определяемому Пользователю веб-сайта <a
                                    href="https://stoodio.vercel.app">stoodio.uz</a>.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>9.</p>
                                <p>
                                    Пользователь – любой посетитель веб-сайта <a
                                    href="https://stoodio.vercel.app">stoodio.uz</a>.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>10.</p>
                                <p>
                                    Предоставление персональных данных – действия, направленные на раскрытие
                                    персональных данных определенному лицу или определенному кругу лиц;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>11.</p>
                                <p>
                                    Распространение персональных данных – любые действия, направленные на раскрытие
                                    персональных данных неопределенному кругу лиц (передача персональных данных) или на
                                    ознакомление с персональными данными неограниченного круга лиц, в том числе
                                    обнародование персональных данных в средствах массовой информации, размещение в
                                    информационно-телекоммуникационных сетях или предоставление доступа к персональным
                                    данным каким-либо иным способом;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>12.</p>
                                <p>
                                    Трансграничная передача персональных данных – передача персональных данных на
                                    территорию иностранного государства органу власти иностранного государства,
                                    иностранному физическому или иностранному юридическому лицу;
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>13.</p>
                                <p>
                                    Уничтожение персональных данных – любые действия, в результате которых персональные
                                    данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления
                                    содержания персональных данных в информационной системе персональных данных и (или)
                                    результате которых уничтожаются материальные носители персональных данных.
                                </p>
                            </div>
                        </div>
                        <div className="policy-texts-chapter chapter-three mt-12">
                            <h4>3. Оператор может обрабатывать следующие персональные данные Пользователя</h4>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>1.</p>
                                <p>Фамилия, имя, отчество;</p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>2.</p>
                                <p>Электронный адрес;</p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>3.</p>
                                <p>Номера телефонов;</p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>4.</p>
                                <p>город;</p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>5.</p>
                                <p>
                                    Также на сайте происходит сбор и обработка обезличенных данных о посетителях с
                                    помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>6.</p>
                                <p>
                                    Вышеперечисленные данные далее по тексту Политики объединены общим понятием
                                    Персональные данные.
                                </p>
                            </div>
                        </div>
                        <div className="policy-texts-chapter chapter-four mt-12">
                            <h4>4. Цели обработки персональных данных</h4>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>1.</p>
                                <p>
                                    Цель обработки персональных данных Пользователя — информирование Пользователя
                                    посредством отправки электронных писем.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>2.</p>
                                <p>
                                    Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и
                                    услугах, специальных предложениях и различных событиях. Пользователь всегда может
                                    отказаться от получения информационных сообщений, направив Оператору письмо на адрес
                                    электронной почты policy@mysite.ru с пометкой «Отказ от уведомлениях о новых
                                    продуктах и услугах и специальных предложениях».
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>3.</p>
                                <p>
                                    Обезличенные данные Пользователей, собираемые с помощью сервисов
                                    интернет-статистики, служат для сбора информации о действиях Пользователей на сайте,
                                    улучшения качества сайта и его содержания.
                                </p>
                            </div>
                        </div>
                        <div className="policy-texts-chapter chapter-five mt-12">
                            <h4>5. Правовые основания обработки персональных данных</h4>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>
                                    Оператор обрабатывает персональные данные Пользователя только в случае их заполнения
                                    и/или отправки Пользователем самостоятельно через специальные формы, расположенные
                                    на сайте <a href="https://stoodio.vercel.app">stoodio.uz</a> Заполняя
                                    соответствующие формы и/или отправляя свои
                                    персональные данные Оператору, Пользователь выражает свое согласие с данной
                                    Политикой.
                                </p>
                            </div>
                        </div>
                        <div className="policy-texts-chapter chapter-six mt-12">
                            <h4>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h4>
                            <p className="mt-5">
                                Настоящая политика обработки персональных данных составлена в соответствии с
                                требованиями
                                Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок
                                обработки персональных данных и меры по обеспечению безопасности персональных данных
                                Станкевича Сергея Александровича (далее – Оператор).
                            </p>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>1.</p>
                                <p>
                                    Оператор обеспечивает сохранность персональных данных и принимает все возможные
                                    меры, исключающие доступ к персональным данным неуполномоченных лиц.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>2.</p>
                                <p>
                                    Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
                                    третьим лицам, за исключением случаев, связанных с исполнением действующего
                                    законодательства.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>3.</p>
                                <p>
                                    В случае выявления неточностей в персональных данных, Пользователь может
                                    актуализировать их самостоятельно, путем направления Оператору уведомление на адрес
                                    электронной почты Оператора policy@mysite.ru с пометкой «Актуализация персональных
                                    данных».
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>4.</p>
                                <p>
                                    Срок обработки персональных данных является неограниченным. Пользователь может в
                                    любой момент отозвать свое согласие на обработку персональных данных, направив
                                    Оператору уведомление посредством электронной почты на электронный адрес Оператора
                                    policy@mysite.ru с пометкой «Отзыв согласия на обработку персональных данных».
                                </p>
                            </div>
                        </div>
                        <div className="policy-texts-chapter chapter-seven mt-12">
                            <h4>7. Трансграничная передача персональных данных</h4>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>1.</p>
                                <p>
                                    Оператор до начала осуществления трансграничной передачи персональных данных обязан
                                    убедиться в том, что иностранным государством, на территорию которого предполагается
                                    осуществлять передачу персональных данных, обеспечивается надежная защита прав
                                    субъектов персональных данных.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>2.</p>
                                <p>
                                    Трансграничная передача персональных данных на территории иностранных государств, не
                                    отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия
                                    согласия в письменной форме субъекта персональных данных на трансграничную передачу
                                    его персональных данных и/или исполнения договора, стороной которого является
                                    субъект персональных данных.
                                </p>
                            </div>
                        </div>
                        <div className="policy-texts-chapter chapter-eight mt-12">
                            <h4>8. Заключительные положения</h4>
                            <div className="mx-1 mt-5 flex items-start gap-x-2">
                                <p>1.</p>
                                <p>
                                    Пользователь может получить любые разъяснения по интересующим вопросам, касающимся
                                    обработки его персональных данных, обратившись к Оператору с помощью электронной
                                    почты policy@mysite.ru.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>2.</p>
                                <p>
                                    В данном документе будут отражены любые изменения политики обработки персональных
                                    данных Оператором. Политика действует бессрочно до замены ее новой версией.
                                </p>
                            </div>
                            <div className="mx-1 flex items-start gap-x-2">
                                <p>3.</p>
                                <p>
                                    Актуальная версия Политики в свободном доступе расположена в сети Интернет по
                                    адресу <a href="https://stoodio.vercel.app/policy">stoodio.uz/policy</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Texts;
